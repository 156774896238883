@import "@causevest/ui-kit/variables";

.wrapper {
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 140px;
  z-index: 100;
  padding: 0 20px;
  border: 1px solid $color-grey-6;
  background: linear-gradient(
    180deg,
    rgba(7, 247, 255, 0.26) -64.81%,
    $color-blue-3 100%
  );
}

.inner {
  margin: auto;
  gap: 76px;
}

.title {
  font-size: 18px;
  color: $color-white;

  > a {
    color: $color-white;
  }
}

.btn {
  position: relative;
  z-index: 1;
  width: 89px;
  height: 26px;
  border-radius: 4px;
  border: 1px solid $color-blue-2;
  background: $color-white;
  box-shadow: 0 7px 20px 0 rgba(15, 105, 220, 0.2);
  color: $color-blue-3;
}
