@import "@causevest/ui-kit/variables";

.wrapper {
  gap: 12px;
}

.title {
  color: $color-black-6;
  font-size: 16px;
  font-weight: 500;
}

.description {
  color: $color-grey-7;
  font-size: 14px;
  font-weight: 400;
}

.link {
  display: flex;
  align-items: center;
  text-decoration: none;
  gap: 5px;
  color: $color-blue;
  font-size: 16px;
  font-weight: 500;
}

.footer {
  margin-top: 30px;

  &__btn {
    width: 120px;
    height: 35px;
  }
}
