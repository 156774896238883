@import "@causevest/ui-kit/variables";

.wrapper {
  display: flex;
  flex-direction: column;
  gap: 48px;
  margin-top: 24px;
  margin-bottom: 24px;
  padding: 15px 24px 24px;
  background: $color-white;
  border-radius: 4px;
}

.title {
  font-size: 24px;
  color: $color-grey-7;
  border-bottom: 1px solid $color-grey-8;
  width: 100%;
  padding-bottom: 15px;
}

.inner {
  gap: 24px;
  margin-top: 24px;
}

.business {
  text-decoration: none;
  color: $color-black;

  &__title {
    @include textEllipsis(100%);
    font-size: 14px;
  }
}

.campaign {
  min-height: 220px !important;
  max-width: 265px;
}

@media (max-width: $sm) {
  .campaign {
    max-width: 100%;
  }
}
