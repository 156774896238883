@import "@causevest/ui-kit/variables";

.categories {
  gap: 22px;
  margin-top: 47px;

  &__wrapper {
    padding: 40px 0;
    background: $color-bg-blue;
  }

  &__item {
    display: flex;
    position: relative;
    width: 124px;
    height: 124px;
    border-radius: 50%;
    overflow: hidden;

    &:after {
      content: "";
      position: absolute;
      z-index: 1;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: rgba(0, 0, 0, 0.6);
      transition: background 0.3s ease-in-out;
    }

    &:hover:after {
      background: rgba(0, 0, 0, 0.8);
    }
  }

  &__pic {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

    &._last {
      left: 6px;
    }
  }

  &__name {
    position: relative;
    z-index: 2;
    font-size: 14px;
    font-weight: 500;
    color: $color-white;
    margin: auto;
    text-align: center;
  }
}

@media (max-width: $sm) {
  .categories {
    margin-top: 30px;
    gap: 15px;

    &__wrapper {
      padding: 35px 0;
      background: $color-bg-blue;
    }

    &__item {
      width: 80px;
      height: 80px;
    }

    &__name {
      font-size: 12px;
    }
  }
}
