@import "@causevest/ui-kit/variables";

.wrapper {
  flex-direction: row;
  justify-content: space-between;
  gap: 40px;
  margin-top: 20px;
}

.form {
  width: 100%;
  gap: 20px;

  &__payment {
    &._isLoading {
      overflow: hidden;
      height: 0;
      visibility: hidden;
    }
  }

  &._light {
    display: flex;
    flex-direction: row;
    align-items: center;
  }
}

.aside {
  max-width: 40%;
  gap: 20px;

  &__title {
    font-size: 18px;
    font-weight: 300;
  }

  &__campaign {
    gap: 5px;
  }

  &__creator {
    font-size: 16px;
    font-weight: 500;
    color: $color-grey-7;
  }

  &__country {
    font-size: 12px;
    color: $color-grey-1;
    margin: 0;
    line-height: 1;
  }
}

.greetings {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 40px;
  border-bottom: 1px solid $color-light-grey;
  height: fit-content;
  width: 100%;

  &._light {
    padding-top: 25px;
    border-top: 1px solid $color-light-grey;
  }

  &__description {
    font-size: 14px;
    font-weight: 300;
    text-align: center;
    color: $color-black-5;
  }
}

.info {
  padding-top: 40px;

  &__title {
    font-size: 18px;
    font-weight: 300;
    line-height: 18px;
  }

  &__inner {
    margin-top: 20px;
    gap: 10px;
    align-items: flex-start;
  }

  &__btn {
    width: 124px;
    height: 35px;
    margin-top: 20px;
    background: transparent;
  }
}

.shareBtn {
  width: 100px;
  height: 35px;

  &__icon {
    height: 17px;
  }
}

@media (max-width: $md) {
  .wrapper {
    flex-direction: column;
    justify-content: flex-start;
    gap: 20px;
  }

  .aside {
    flex-direction: row;
    gap: 20px;
    align-items: flex-start;

    &__creator {
      white-space: nowrap;
    }

    &__campaign {
      min-width: 300px;
    }
  }
}

@media (max-width: $sm) {
  .aside {
    flex-direction: column;
    width: 100%;

    &__campaign {
      order: 2;
      min-width: 280px;
    }
  }
}